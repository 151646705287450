
export const TestimonialsData=[

    {
        id:1,
        title:"Proven Track Record",
        description:'Proven success across businesses – Explore our impactful portfolio.',
    
    },
    {
        id:1,
        title:"Transparent Communication",
        description:'Stay informed with transparent updates on campaign performance.',
 
    },
    {
        id:1,
        title:"Client-Centric Approach",
        description:'Your goals are ours; collaboration for shared success',
   
    },
    {
        id:1,
        title:"Innovative Solutions:",
        description:'Cutting-edge strategies for a unique, standout online presence',
     
    },
    {
        id:1,
        title:"Our Adaptability",
        description:'Swift adaptation to industry trends for continuous campaign success',
         
    },
    {
        id:1,
        title:"Continuous Optimization",
        description:'Real-time data-driven optimization for consistently high performance',
     
    }
    
    ,
    {
        id:1,
        title:"Industry Insights",
        description:"In-depth knowledge for effective positioning within your industry."
       
    }
    ,
    {
        id:1,
        title:"Strategic Partnerships",
        description:'Leveraging connections for amplified brand presence and credibility.',
  
    }
    
    ,
    {
        id:1,
        title:"Holistic Approach",
        description:"Comprehensive strategies for an impactful overall digital brand presence."
    }
    

]