import React from 'react'
import './intro.css'
import img1 from '../../img/d7.png'
import img2 from '../../img/d2.png'
import img3 from '../../img/d3.png'
import 'bootstrap/dist/css/bootstrap.min.css';


const Intro = () => {

  
  return (
    <>
    <div className="Intro" id="home">
    <div className="intro-container">
      <span className="intro-main-heading">LET'S DIGITALIZE NOW!</span>
      <span className="intro-main-paragraph">
      Step into the future with Digitalize, where innovation meets success. Let Digitalize elevate your brand with strategic digital advertising solutions,  your partner in transforming visions into digital triumphs.
      </span>

      <span className='intro-main-hastags'>#MultiPlatformMastery
#AdvertiseThriveSucceed
#ClientSuccessJourney
#CreativeAdvertisingBrilliance</span>
      
      {/* <div className="socialmedia-icon">
        <div class="social-container">
          <ul class="social-icons">
            <li>
              <a href="fb">
                <i class="bi bi-facebook"></i>
              </a>
            </li>
            <li>
              <a href="youtube">
              <i class="bi bi-snapchat"></i>
              </a>
            </li>
            <li>
              <a href="youtube">
              <i class="bi bi-bing"></i>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
      

      <div className="intro-images">
        <div className="image_wrapper img-1">
          <img src={img1} alt="" />
          <div className="overlay overlay_1">
  
          </div>
        </div>

        <div className="image_wrapper img-2">
          <img src={img2} alt="" />
          <div className="overlay overlay_1">
   
          </div>
        </div>

        <div className="image_wrapper img-3">
          <img src={img3} alt="" />
          <div className="overlay overlay_1">
  
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* <div className='intro-svg'> */}
  {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#FFF" fill-opacity="1" d="M0,288L720,192L1440,320L1440,0L720,0L0,0Z"></path></svg> */}
  {/* </div> */}
  </>
  )
}

export default Intro
