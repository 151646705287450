import React, { useEffect } from "react";
import "./footer.css";
import mmf_logo from "../../img/DigitalizeLogo.png";

import {
  FaTwitter,
  FaYoutube,
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaPhone,
  FaEnvelope,
  FaSnapchat,
} from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-scroll";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="dg-footer">
                <div className="foot-img">
                  <img src={mmf_logo} alt="" />
                </div>
                <p>
                Innovative digital advertising partner, tailored for success
                </p>
                <div className="foot-icon">
                  <a
                    target="_blank"
                    href="https://m.facebook.com/mehfoozmustaqbilfoundation"
                  >
                    <FaFacebookF />
                  </a>
                  <a target="_blank" href="https://twitter.com/mmfoundationpk">
                    <FaSnapchat />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <div className="foot-about">
                <h5>Menu</h5>
                <ul>
                  <Link spy={true} to="intro-section" smooth={true}>
                    <li>
                      <a href="/#home">Home</a>
                    </li>
                  </Link>
                  <Link spy={true} to="whoweare-section" smooth={true}>
                    <li>
                      <a href="/#whoweare">Who we are</a>
                    </li>
                  </Link>
                  <Link spy={true} to="services-section" smooth={true}>
                    <li>
                      <a href="/#campaign">Our Services</a>
                    </li>
                  </Link>

                  <Link spy={true} to="faqs-section" smooth={true}>
                    <li>
                      <a href="/#contactus">FAQs</a>
                    </li>
                  </Link>

                  <Link spy={true} to="join-us-section" smooth={true}>
                    <li>
                      <a href="/#joinus">Join us</a>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 footer-end">
              <div className="foot-contact">
                <h5>Contact Info</h5>
                <div className="footer-contact-a">
                  <i className=" circle-icon">
                    <FaPhone/>
                  </i>
                  <a href="tel:+44 7467037683" className="contact-info">
                  <span> Call us</span>
                  <span>+44 7467037683</span>
               </a>
                  </div>

                <div className="footer-contact-a contact-footer">
                  <i className="circle-icon">
                    <FaEnvelope/>
                  </i>
                  <a href="mailto: adamchurch@digitalizeltd.org" className="email-info">
                  adamchurch@digitalizeltd.org
                    <span> Email Us</span>
                    <span> </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <div className="foot-end py-2">
              <small>
                Website  Developed  for Digitalize © 2024 All Rights Reserved{" "}
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
