import React,{useEffect} from 'react'
import img1 from '../../img/d3.png'
import img2 from '../../img/d7.png'
import img3 from '../../img/d1.png'
import img4 from '../../img/d8.png'

import Aos from "aos";
import "aos/dist/aos.css";
const Advertising = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
    return(
<div class="advertising-con container"  id="services-section" data-aos="fade-up">
        <h3 class="text-center mt-5 display-5 fw-bold overflow-hidden">Our <span class="theme-text">Services</span></h3>
        <hr class="mx-auto mb-5 w-25"/>
        <div class="row mb-5">
            <div class="col-12 col-sm-6 col-md-3 m-auto" data-aos="fade-right">
                {/* <!-- card starts here --> */}
                <div class="card shadow">
                    <img src={img3} alt="" class="card-img-top"/>
                    <div class="card-body">
                        <h3 class="text-center  overflow-hidden">Social Media Advertising</h3>
                        <hr class="mx-auto w-75"/>
                        <p>
                        Strategic digital ads boost brand awareness and customer perceptions on diverse platforms ,also positive perceptions across diverse online platforms
                        </p>
                    </div>
                </div>
                {/* <!-- card ends here --> */}
            </div>
            {/* <!-- col ends here --> */}
            <div class="col-12 col-sm-6 col-md-3 m-auto" data-aos="fade-right">
                {/* <!-- card starts here --> */}
                <div class="card shadow">
                    <img src={img4} alt="" class="card-img-top"/>
                    <div class="card-body">
                        <h3 class="text-center  overflow-hidden">Search Engine Marketing (SEM)</h3>
                        <hr class="mx-auto w-75"/>
                        <p>
                        Through tools like  businesses can re-engage users who have previously interacted with their website or shown interest in their products. 
                        </p>
                    </div>
                </div>
                {/* <!-- card ends here --> */}
            </div>
            {/* <!-- col ends here --> */}
            <div class="col-12 col-sm-6 col-md-3 m-auto" data-aos="fade-left">
                {/* <!-- card starts here --> */}
                <div class="card shadow">
                    <img src={img1} alt="" class="card-img-top"/>
                    <div class="card-body">
                        <h3 class="text-center  overflow-hidden">Display Advertising</h3>
                        <hr class="mx-auto w-75"/>
                        <p>
                        Using remarketing, businesses re-engage users who've interacted with their site or shown interest. This reminder prompts return visits and boosts purchases.
                       </p>
                    </div>
                </div>
                {/* <!-- card ends here --> */}
            </div>
            {/* <!-- col ends here --> */}
            <div class="col-12 col-sm-6 col-md-3 m-auto" data-aos="fade-left">
                {/* <!-- card starts here --> */}
                <div class="card shadow">
                    <img src={img2} alt="" class="card-img-top"/>
                    <div class="card-body">
                        <h3 class="text-center  overflow-hidden">Remarketing</h3>
                        <hr class="mx-auto w-75"/>
                        <p>Digital ads are cost-effective, letting businesses set budgets, target audiences, and pay for performance, ensuring optimal marketing value.
                        </p>
                    </div>
                </div>
            </div>
           
        </div>
       
    </div>


 )}
export default Advertising
