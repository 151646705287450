
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Advertising from './components/Advertising/Advertising';
import Footer from './components/Footer/Footer';
import Intro from './components/Intro/Intro';
import NavSocialLink from './components/NavSocialLink/NavSocialLink';
import Navbar from './components/Navbar/Navbar';
import WhoWeAre from './components/WhoWeAre/WhoWeAre';
import Joinus from './components/JoinUs/Joinus';
import Account from './components/Accounts/Account';
import AboutDigitalize from './components/AboutDigitalize/AboutDigitalize';
import FAQs from './components/faqs/FAQs';

function App() {
  return (
    <>

      {/* <NavSocialLink/> */}
    <Navbar/>
    <Intro/>
    <WhoWeAre/>
  <AboutDigitalize/>
    <Advertising/>
    <Joinus/>

    <FAQs/>
<Account/>
    <Footer/>
    </>);
}

export default App;
