// about us

import React, { useEffect } from 'react'
import SympImg from '../../img/d9.png'
import SymImg2 from '../../img/d5.png'
import './aboutdigitalize.css'

const AboutSoormiyun = () => {
 
  return (
    <>
    <div className='DigitalizeAbout-Section' id="our-mission">
      
      <div className='left-digitalizeaboutSections' >
  <h2>
  <span className='aboutus-heading'>OUR MISSION</span>
  </h2>
  <h3>
  <span className='aboutus-subheading'>Elevate your brands with digitalize</span>
  </h3>
  <span className='aboutus-paragraph'>
  Our mission is to be the dedicated partner that elevates your brand's online presence through tailored and innovative digital advertising solutions, ensuring measurable success    </span>
       <span><button className='button-6'>Contact Us</button></span>

      </div>
      <div className='right-digitalizeaboutSections' data-aos="fade-right">
      <div className='img1-digitalizeaboutSections' data-aos="fade-down">
      <img src={SympImg}></img>
      </div>
      <div className='img2-digitalizeaboutSections' >
      <img src={SymImg2}></img>
      </div>
      
      </div>
    
      </div>
    
      </>
      )
}

export default AboutSoormiyun