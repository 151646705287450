import React from "react";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import logo from "../../img/logo/logo.png";
import "./navbar.css";
import { Link } from "react-scroll";
const Navbar = () => {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const closeNavbar = () => {
    navRef.current.classList.remove("responsive_nav");
  };
  return (
    <div className="Navbar-container">
      <header className="container-fluid">
        <img src={logo} alt="logo" className="nav-logo"></img>

        <nav ref={navRef}>
          <Link
            spy={true}
            to="intro-section"
            smooth={true}
            onClick={closeNavbar}
          >
            <a href="/#home">Home</a>
          </Link>
          <Link
            spy={true}
            to="whoweare-section"
            smooth={true}
            onClick={closeNavbar}
          >
            <a href="/#whoweare">Who We Are</a>
          </Link>
          <Link
            spy={true}
            to="services-section"
            smooth={true}
            onClick={closeNavbar}
          >
            <a href="/#campaign">Our Services</a>
          </Link>
          <Link
            spy={true}
            to="faqs-section"
            smooth={true}
            onClick={closeNavbar}
          >
            <a href="/#contactus">FAQs</a>
          </Link>
          <Link
            spy={true}
            to="join-us-section"
            smooth={true}
            onClick={closeNavbar}
          >
            <a href="/#joinus">Join Us</a>
          </Link>

          {/* <a href="#" class="donate">
            <Link
              spy={true}
              to="advertise-business-section"
              smooth={true}
              onClick={closeNavbar}
            >
            LET'S DIGITALIZE
            </Link>
          </a> */}
          {/* <button className="join-now-btn">
        <a href="join" className="join-now">
          Join Now
        </a>
      </button> */}
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn nav-bar-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
};

export default Navbar;
