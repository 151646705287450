import React, { useEffect } from "react";
import "./whoweare.css";
import frame from "../../img/d8.png";
import CheckmakerContainer from "../CheckmakerContainer/CheckmakerContainer";
import Aos from "aos";
import "aos/dist/aos.css";
const WhoWeAre = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="Whoweare" id="whoweare-section">
      <div className="whoweare-section container"  >
        <div className="left-section" data-aos="fade-right">
          <img src={frame}></img>
        </div>
        <CheckmakerContainer
          header="Who are we?"
          heading="Digital Excellence: Crafting Your Online Triumph"
          text="Fueled by a passion for innovation and a commitment to excellence, we are dedicated to helping businesses thrive in the dynamic digital landscape."
          cm1="Strategic digital partners dedicated to your online success through innovative solutions"
          cm2="Crafting unique, results-driven advertising strategies tailored to your business goals"
          cm3="Maximizing online reach across diverse platforms in the ever-evolving digital landscape."
          cm4="Continuously optimizing campaigns with a data-driven approach to stay ahead of industry trends"
        ></CheckmakerContainer>
      </div>
    </div>
  );
};

export default WhoWeAre;
