import React, { useEffect, useState } from "react";
import "./faqs.css";
import FAQ from "./FaqsComp";

import Aos from "aos";
import "aos/dist/aos.css";
export default function FAQs() {
  const [faqs, setFaqs] = useState([
    {
      question: " How does Digitalize tailor advertising strategies for businesses?",
      answer:
        " We understand each business's uniqueness and craft personalized advertising solutions.",
      open: true
    },
    {
      question: "How does Digitalize ensure campaign success?",
      answer: "Our data-driven approach continually optimizes campaigns based on user behavior and industry trends.",
      open: false
    },
    {
      question:
        "How can I reach out to Digitalize for digital advertising services?",
      answer: "Feel free to contact us via email at adamchurch@digitalizeltd.org or give us a call at +44 7467037683 to discuss your advertising needs.",
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="App" id="faqs-section">
    <h3 data-aos="fade-down" class="text-center mt-5 display-5 fw-bold overflow-hidden"> <span class="theme-text">FAQs</span></h3>
      
      <div className="faqs">
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))} 
      </div>
    </section>
  );
}

