// npm i react-slick --save
// npm i slick-carousel --save

// https://react-slick.neostack.com/docs/example/responsive
import React,{useEffect} from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { TestimonialsData } from './data'
import './joinus.css'

import Aos from 'aos'
import 'aos/dist/aos.css'

const Joinus = () => {
  

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
      autoplayTimeout: 1000,
      autoplayHoverPause: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };


      useEffect(() => {
        Aos.init({ duration: 2000 });
      }, []);
  return (
    <section class="Joinus-Section container "  id='join-us-section'>
 <h3 class="text-center mt-5 mb-5 display-5 fw-bold overflow-hidden"  data-aos="fade-down">Why <span class="theme-text">Choose Us?</span></h3>
      
<Slider {...settings} >
{TestimonialsData.map((item) => (

    <div className="joinus-card" >       

<div className="joinus-card-bottom">
<h6>{item.title}</h6>
<p >{item.description}</p>
</div>
</div>
   
))}
</Slider>


    </section>
  )
}

export default Joinus
