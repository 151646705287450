import React, { useEffect } from 'react'
import './account.css'
import bing from '../../img/logo/bing.svg'
import dv360 from '../../img/logo/DV360.svg'
import fb from '../../img/logo/facebook.png'
import mediago from '../../img/logo/mediaGo.webp'
import outbrain from '../../img/logo/outbrain.png'
import snapchat from '../../img/logo/snapchat.png'
import taboola from '../../img/logo/Taboola.png'

import bigo from '../../img/logo/bigo.png'
import applovin from '../../img/logo/applovin.png'
import zemanta from '../../img/logo/Zemanta.png'
import xander from '../../img/logo/Xandr.png'

import Aos from "aos";
import "aos/dist/aos.css";
const Account = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    // HTML<!-- Client 1 - HCF Bootstrap 5 Component -->
<section class="bg-light py-5 py-xl-6">
  <div class="container mb-5 mb-md-6">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 text-center">
      <h3 class="text-center mt-1 display-5 fw-bold overflow-hidden"  data-aos="fade-down">Accounts we work with! <span class="theme-text"></span></h3>

      <p class="text-secondary mb-4 mb-md-5">
      
Elevate online success through innovative digital advertising; we're dedicated partners, crafting tailored strategies with a client-centric focus for measurable results
      </p>
        <hr class="w-50 mx-auto mb-0 text-secondary"/>
      </div>
    </div>
  </div>
  <div class="container overflow-hidden">
    <div class="row gy-5 gy-md-6">
      <div class="col-6 col-md-3 align-self-center text-center">
          <img src={bigo} alt=""  width="105" height="85"  />
       </div>
      <div class="col-6 col-md-3 align-self-center text-center">
        <img src={bing} alt=""  width="105" height="85"  />
          </div>
    
      <div class="col-6 col-md-3 align-self-center text-center">
         <img src={outbrain} alt=""  width="105" height="85"  />
          </div>
      <div class="col-6 col-md-3 align-self-center text-center">
          <img src={applovin} alt=""  width="105" height="85"  />
        </div>
      <div class="col-6 col-md-3 align-self-center text-center">
        <img src={zemanta} alt=""  width="105" height="85"  />
        </div>
      <div class="col-6 col-md-3 align-self-center text-center">
          <img src={xander} alt=""  width="105" height="85"  />
              </div>
      
              <div class="col-6 col-md-3 align-self-center text-center">
          <img src={taboola} alt=""  width="105" height="85"  />
              </div>
              <div class="col-6 col-md-3 align-self-center text-center">
        <img src={dv360} alt=""  width="105" height="85"  />
       </div>
    </div>
  </div>
</section>
  )
}

export default Account
